<template>
    <Card>
        <template #header> </template>
        <template #title>
            Create a new Account
        </template>
        <template #content>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
        </template>
        <template #footer>
            <div class="d-grid gap-2">
                <button
                    type="button"
                    class="btn btn-success"
                    @click="addAccount()"
                >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add an Account
                </button>
            </div>
        </template>
    </Card>
</template>

<script lang="js">

export default {
    name: 'AccountRoleAdd',
    data: () => {
        return {

        }
    },
    methods: {
        addAccount() {
            this.$router.push({ name: 'Account Add'})
        }
    },
}
</script>
<style scoped lang="scss">
#add-btn {
    width: 200px;
}
</style>
