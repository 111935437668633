<template>
    <div class="card" v-if="ready">
        <Card>
            <template #header> </template>
            <template #title>
                {{ account.new_businessname || 'No name!' }}
            </template>
            <template #subtitle>
                {{ account.name || '' }}
            </template>
            <template #content>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Inventore sed consequuntur error repudiandae numquam
                    deserunt quisquam repellat libero asperiores earum nam
                    nobis, culpa ratione quam perferendis esse, cupiditate neque
                    quas!
                </p>
                <Message
                    v-bind:severity="statusClass(account.statuscode_value)"
                    v-bind:closable="false"
                    style="margin-top:10px"
                >
                    Account status is
                    {{ account.statuscode_value || 'NA' }}</Message
                >

                <Message
                    v-bind:severity="statusClass(role.statuscode_value)"
                    v-bind:closable="false"
                    style="margin-top:10px"
                >
                    {{ role._new_contactroletype_value || '' }} Role status is
                    {{ role.statuscode_value }}</Message
                >
            </template>
            <template #footer>
                <div class="d-grid gap-2">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="redirectToAccount()"
                    >
                        View Account
                    </button>
                </div>
            </template>
        </Card>
    </div>
    <div v-else>
        <Card>
            <template #header> </template>
            <template #title>
                <Skeleton height="5rem" />
            </template>
            <template #content>
                <Skeleton height="10rem" />
            </template>
            <template #footer>
                <Skeleton height="3rem" width="25%" />
            </template>
        </Card>
    </div>
</template>

<script lang="js">

export default {
    name: 'AccountRoleAddPanel',
    data: () => {
        return {
            account: null
        }
    },
    props: {
        role: {type: Object, required: true}
    },
    computed: {
        ready() {return (this.account) ? true : false}
    },
    methods: {
    statusClass(value) {
        if (value && value == 'Active') {
            return 'success'
        }
        return 'warn'
    },
    redirectToAccount() {
        this.$router.push({ name: 'Account', params: { id: this.account.accountid } })
    }
    },
    async created() {
        this.account = await this.$store.dispatch('retrieveAccount', {id: this.role.accountid})
    }
}
</script>

<style scoped lang="scss">
#status-list {
    padding-bottom: 40px;
}
.status-badge {
    min-width: 120px;
}
</style>
